.summary-box {


}

.tabs {
	display: block;
	
	UL {
		display: block;
		padding: 0;
		margin: 0;
		
		LI {
			display: inline-block;
			@include SSTFontFamily( 'bold', 16px, 1.2em );
			text-transform: uppercase;
			margin: 0;
			padding: 15px 40px;
			border-right: 1px solid $swatch-white;
			background: $swatch-mid-silver;
			
			&:last-child {
				border-right: 0;
			}
			
			&:hover {
				color: $swatch-white;
				background-color: $swatch-satin-blue;
				cursor: pointer;
			}
			&.active {
				color: $swatch-white;
				background-color: $swatch-satin-blue;
			}
		}
	}
}

.status-icon-block {
	min-width: 120px;
	.status-icon {
	    position: absolute;
	    right: 10px;
	    top: 10px;
	}
}
.advanced-search-head {
	border-top: 1px solid $swatch-white;
	cursor: pointer;
	
	h5 {
		margin: 0;
		background-image: url("#{$images_path}icons-sprites.png");
		background-repeat: no-repeat;
		background-position: 110% -596px;
	}
	
	&.opened {
		H5 {
			background-position: 110% -637px;
		}
	}
}

.advanced-search-box {

	BUTTON {
		display: block;
		width: 40px;
	}
}