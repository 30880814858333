//@import url( 'https://assets.software.eu.playstation.com/fonts/fonts.css' );
@import 'mixins';
@import "fonts";

$font-family-normal : 'SST W01 Roman', 'SST W02 Roman','SST W10 Roman','SST W15 Roman', Arial, Helvetica;
$font-family-bold   : 'SST W01 Bold', 'SST W02 Bold','SST W10 Bold','SST W15 Bold','Arial Bold', Helvetica, Arial;
$font-family-bebas  : 'BebasRegular', Arial, Helvetica, sans-serif;
$font-family-hand   : 'jrhandregular', Arial, Helvetica, sans-serif;
$font-family-adelle : 'adelle', Georgia;

$swatch-white: #fff;
$swatch-black: #363636;
$swatch-device-black: #1f1f1f;
$swatch-text-grey: #363636;
$swatch-satin-blue: #0068bf;
$swatch-highlight-glow: #00a2ff;
$swatch-base-blue: #003791;
$swatch-text-highlight: #8fdffd;
$swatch-mid-silver: #eee;
$swatch-mid-silver-dark: #e6e6e6;
$swatch-silver: #ededed;
$swatch-mid-silver-white: #f5f5f5;
$swatch-light-silver: #f5f5f5;
$swatch-orange: #ed5f2b;
$swatch-yellow: #f1c40f;
$swatch-green: #32a82c;

// common stuff

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    &:after,
    &:before {
        box-sizing: border-box;
    }
}

html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

body {
    background-color: $swatch-white;
    color: $swatch-text-grey;
    @include SSTFontFamily( 'normal', 16px, 1.2em );
}

h1, h2, h3, h4, h5, h6,
.font-bold {
    font-weight: normal;
    @include SSTFontFamily( 'bold' );
}

h1 {
    font-size: 38pt;
    line-height: 1.3em;
    margin-bottom: 20px;
}

h2 {
    font-size: 28pt;
    line-height: 1.2em;
    margin-bottom: 20px;
}

h3 {
    font-size: 24pt;
    line-height: 1.2em;
    margin-bottom: 20px;
}

h4 {
    font-size: 20pt;
    line-height: 1.2em;
    margin-bottom: 20px;
}

h5 {
    font-size: 16pt;
    line-height: 1.2em;
    margin-bottom: 20px;
}

h6 {
    font-size: 16pt;
    line-height: 1.2em;
    margin-bottom: 20px;
    @include SSTFontFamily( 'normal' );
}

.font-normal {
    @include SSTFontFamily( 'normal' );
}

.wrap {
    max-width: 940px;
    margin: 0 auto;
}

.clear {
    clear: both;
}

.hidden {
    display: none !important;
}

img {
    max-width: 100%;
    height: auto!important;
}
