.progressbar {
	width: 100%;
	height: 5px;
	background: $swatch-mid-silver;
	
	.progress {
		  display: block;
		  height: 5px;
		  background-color: $swatch-satin-blue;
		  position: relative;
		  overflow: hidden;
		  transition: width 500ms ease 0s;
		  
	}
}