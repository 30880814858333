.with-min-height {
  min-height: 500px;
}
.contents {
	/*min-height: 400px;*/
	padding: 50px 0;
	overflow: hidden; /*might need to remove this later*/

	&.first-contents {
		margin-top: -100px;
	}
	
	h2 {
		margin-bottom: 50px;
	}
}

.wrapper {
	margin: 0 auto;
	max-width: 940px;
}

.contact-number {
	font-family: BebasRegular;
	font-weight: normal;
	font-size: 48px;
	line-height: 48px;
	color: #0068bf;
	margin-bottom: 30px;
}

.black-box {
	padding: 25px;
	background: $swatch-text-grey;
	color: #fff;
	margin: 20px 0;
	
	p {
		text-align: center;
		@include SSTFontFamily( 'bold', 16px, 1.2em );
	}
}
p .small {
	font-size: 0.9em;
}
.home-box {
	padding-top: 20px;
	
	.home-box-holder {
	
		.list-box {
			display: table-cell;
			width: 469px;;
			border-right: 2px solid $swatch-mid-silver;
			text-align: center;
			vertical-align: top;
		
			&:last-child {
				border-right: 0;
			}
			.inner-list-box {
				display: block;		
				text-align: center;
				width: 100%;
				
				.inner-box{
					max-width: 300px;
					margin: 0 auto;
				
					A {
						position: relative;
						width: 200px;
						height: 200px;
						margin: 0 auto;
						text-indent: -900px;
						overflow: hidden;
						display: block;
						float: none;
						background-color: $swatch-mid-silver;
						background-repeat: no-repeat;
						background-image: none;
						
						transition: background 250ms ease-in-out;
						
					}
					.shadow {
						height: 70px;
						width: 200px;
						margin: 0 auto;
						background-color: $swatch-white;
						background-image: url("#{$images_path}home-sprites.png");
						background-repeat: no-repeat;
						background-position: 20px -400px;
					}
					h4 {
					
					}
					p {
					
					}
				}
			}
			&.product {
				.inner-list-box {
					.inner-box{
						A{
							background-image: url("#{$images_path}home-sprites.png");
							background-position: 35px -150px;
							
							
							&:before {
								content: '';
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								background-image: url("#{$images_path}home-sprites.png");
								background-repeat: no-repeat;
								background-position: -230px -150px;
								opacity: 0;
							}
							
							&:hover {
								background-color: $swatch-satin-blue;
								&:before {
									opacity: 1;
								}
							}
						}
					}
				}
			}
			&.summary {
				.inner-list-box {
					.inner-box{
						A{
							background-image: url("#{$images_path}home-sprites.png");
							background-position: 25px 50px;

							&:before {
								content: '';
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								background-image: url("#{$images_path}home-sprites.png");
								background-repeat: no-repeat;
								background-position: -235px 50px;
								opacity: 0;
							}
							
							&:hover {
								background-color: $swatch-base-blue;
								&:before {
									opacity: 1;
								}
							}
						}
					}
				}
			}
		}
	}
}

.contact-box,
.errors-box {
	display: block;
	max-width: 880px;
	margin: 0 auto;
	font-size: 18px;
	
	.contact-number {
		display: block;
		clear: both;
		margin-bottom: 30px;
	}
	h2 {
		margin-bottom: 15px;
	}
}

.popinfo {

	IMG {
	
		&.right {
			margin-lef: 20px;
			float: right;
		}
		&.left {
			margin-right: 20px;
			float: left;
		}
	}
}
