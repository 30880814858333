/* ===============================
 * LINK BUTTONS
 * ===============================*/

.link-btn {
	cursor: pointer;
	text-decoration: none;
	color: #ffffff;
	text-align: center;
	display: inline-block;
	min-width: 140px;
	position: relative;
	text-transform: uppercase;	
	@include SSTFontFamily( 'bold', 16px );

	&.btn {
    	padding: 15px;
   		text-shadow: 0px -1px 1px rgba(0, 0, 0, 0.3);
	}
	&.btn.short {
      padding: 8px 15px;
	}
	&:hover {
		text-decoration: none !important;
    }
	.icon {
		position: absolute;
		top: 5px;
		left: 15px;
	}
	.imageText {
		padding-left: 45px;
    }
    
    &:disabled,
    &.disabled {
		opacity: 0.5;  
		pointer-events: none;
		cursor: default; 
    }
}

.small-btn {
	font-size: 14px;
}

.small-btn .btn {
	padding-top: 10px;
	padding-bottom: 10px;
}

.black-btn {
	background-color: #1f1f1f;
}
.cssgradients .black-btn {
	@include background-color(#1f1f1f, #424242);
}
.black-btn:hover {
	background-image: none;
	background-color: #1f1f1f;
}


.blue-btn {
	background-color: #0070c7;
}
.cssgradients .blue-btn {
	@include background-color(#0070c7, #008ae3);
}
.blue-btn:hover {
	background-image: none;
	background-color: #0070c7;
}

.white-btn {
	background-color: #d5d6d7;
	color: #363636 !important;
}

.cssgradients .white-btn {
	@include background-color(#d5d6d7, #eeeeee);
}
.white-btn:hover {
	background-image: none;
	background-color: #d5d6d7;
}

.orange-btn {
	background-color: #e55526;
}

.cssgradients .orange-btn {
	@include background-color(#e55526, #f96f38);
}

.orange-btn:hover {
	background-image: none;
	background-color: #e55526;
}

.lt-ie9 .btn {
	padding: 13px 20px;
}
.lt-ie9 .black-btn,
.lt-ie10 .black-btn {
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#020202', endColorstr='#2a2a2a');
}
.lt-ie9 .red-btn,
.lt-ie10 .red-btn {
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e04c25', endColorstr='#fe7435');
}
.lt-ie9 .blue-btn,
.lt-ie10 .blue-btn {
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0148a0', endColorstr='#016dc5');
}
.lt-ie9 .white-btn,
.lt-ie10 .white-btn {
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d7d8d8', endColorstr='#ebebeb');
}
.lt-ie9 .orange-btn,
.lt-ie10 .orange-btn {
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f86d37', endColorstr='#e55526');
}
.lt-ie9 .orange-btn:hover,
.lt-ie10 .orange-btn:hover {
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e55526', endColorstr='#e55526');
}


BUTTON,
INPUT[type="submit"],
INPUT[type="button"] {
	border: medium none;
    border-radius: 0;
}