A{
	color: $swatch-satin-blue;
	transition: color 250ms;
	@include SSTFontFamily( 'normal', 16px, 1.2em );
}

SECTION {
	position: relative;
	background: #fff;
}
SECTION:before, SECTION:after {
    content: "";
    display: table;
}
SECTION:after {
    clear: both;
}			
.clearfix{
	
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	
	&:after {
		clear: both;
	}
}

.mask {
	background-color: #ffffff;
    height: 100px;
    /*max-width: 1040px;*/
    position: absolute;
    width: 100%;

	&.middle-mask {
	    bottom: 0;
	    left: 50%;
	    margin-left: -520px;
	}
	&.hero-mask {
	    bottom: 0;
	    left: 50%;
	    margin-left: -520px;
	}
	&.left-top {
	    margin-right: 600px;
	    right: 50%;
	    top: 0;
	}
	&.right-bottom{
	    bottom: 0;
	    left: 50%;
	    margin-left: 600px;
	}
}
.align-left {
	text-align: left !important;
}
.align-center {
	text-align: center !important;
}
.align-right {
	text-align: right !important;
}

.float-left {
	float: left !important;
}
.float-right {
	float: right !important;
}

.full-width {
	width: 100% !important;
}
.half-width {
	display: inline-block;
	width: 49.5%;
}

.third-width {
	display: inline-block;
	width: 33% !important;
}

.clear-right {
	clear: right !important;
}

.clear-left {
	clear: left !important;
}

.icon {
	display: inline-block;
	width: 40px;
	height: 40px;
	background-repeat: no-repeat;
	background-image: url("#{$images_path}icons-sprites.png");
	
	&.icon-ps {

	}
	&.icon-tick {
		height: 20px;
		background-position: 0 -259px;
	}
	&.icon-alert {
		width: 66px;
		height: 66px;
		background-position: 0 -77px;		
	}
	&.icon-disk {
		width: 66px;
		height: 66px;
		background-position: 0 -172px;	
		
	}
	&.icon-cross {
		background-position: 0 -316px;
	}
	&.icon-info {
		background-position: 0 -384px;	
	}
	&.icon-pdf {
		background-position: 0 -455px;
		height: 45px;
	}
	&.icon-search {
		background-position: 0 -527px;	
	}
	&.icon-totop {
		width: 23px;
		background-position: 0 -670px;
	}
	&.small {
	    background-size: 30px 300px;
	    height: 20px;
	    width: 25px;
	    
		&.icon-alert {
			background-position: 0 -25px;	
		}
		&.icon-disk {
			background-position: 0 -56px;	
		}
	}
	
	&.icon-package-white {
		backgroung-repeat: no-repeat;
		background-image: url("#{$images_path}home-sprites.png");
		background-position: -57px -37px;
		background-size: 103px 120px;
		width: 45px;
		height: 50px;
	}
}
.row {
	display: table-row;
}
.column {
	display: table-cell;
	text-align: left;
	vertical-align: top;
	width: 42%;
	min-width: 420px;
	
	&:last-child {

	}
	
	&.spacer {
		width: 6%;
		min-width: 20px;
	}
}
.inline {
	display: inline-block;
	
	&.left{
		float: left;
	}
	&.right {
		float: right;
	}
}


.moveup {
	margin-top: -15px;
	margin-bottom: 10px;
}

.bar {
	padding: 20px;
	display: block;
}
.box {
	margin: 20px 0;
}
	
.black {
	background-color: $swatch-black !important;
	color: $swatch-white;
}
.grey {
	background-color: $swatch-mid-silver !important;
}
.light-grey {
	background-color: $swatch-mid-silver-white !important;
}
.highlight-glow {
	color: $swatch-highlight-glow;
}
.satin-blue {
	color: $swatch-satin-blue;
}
.green {
	background-color: $swatch-green;
	color: $swatch-white;
}
.orange {
	background-color: $swatch-orange;
}

.yellow {
	background-color: $swatch-yellow;
}
.mid-silver-white {
	background-color: $swatch-mid-silver-white;
}


UL.regular {
    list-style-type: none;
    margin: 0;
    padding: 0;
	counter-reset: li;

	li::before {
	    color: #0068c3;
	    content: "■";
	    font-size: 13px;
	    left: -15px;
	    position: absolute;
	    top: 0;
	}
	li {
	    font-size: 16px;
	    line-height: 1.5;
	    list-style: outside none none;
	    margin: 20px 0 15px 16px;
	    min-height: 30px;
	    padding: 0;
	    position: relative;
	}
}

h5.header {
	display: block;
	background-color: #363636;
    color: #fff;
    margin: 0;
    padding: 10px;
    margin-right: 3px;
}

.table-striped {
	width: 100%;
	
	TR {
		
		TD{
			padding: 15px;
    		text-align: left;
		}
	
		&:nth-child(odd) {
			background-color: #f5f5f5;
		}
	
		&:nth-child(even) {
			background-color: #eee;
		}
	}

	
	
}