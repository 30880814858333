.paginations-container {
	display: table;
	width: 100%;
	margin-bottom: 20px;
	
	&:last-child {
		margin-top: 20px;
		margin-bottom: 0;
	}
	.pagi-title {
		display: table-cell;
		text-align: left;
		vertical-align: middle;
	}

	.pagi {
		text-align: right;
		display: table-cell;
		vertical-align: middle;
		
		A.pagi-50 {
			display: inline-block;
			text-align: right;
			padding-right: 20px;
			line-height: 40px;
		}
		
		UL.paginations {
			display: inline-block;
			float: right;
			
			LI {
				display: inline-block;
				width: 40px;
				height: 40px;
				text-align: center;
				margin-right: 2px;
				background-color: $swatch-mid-silver-white;
				@include SSTFontFamily( 'bold', 16px, 40px );
				float: left; 
				
				A {
					@include SSTFontFamily( 'bold', 16px, 40px );
					text-decoration: none;
					color: $swatch-black;
					display: block;
				}
				&:first-child{
					margin-right: 10px;
				}
				&:last-child {
					margin-right: 0;
					margin-left: 8px;
				}
				
				&.dotdot{
					color: $swatch-satin-blue;
				}
				
				&.prev {
					background-image: url("#{$images_path}chevron-sprites.png");
				    background-position: -1px 0;
				    background-size: 99px 100px;

					&.disabled {
						opacity: 0.5;  			
						A{
							opacity: 0.5;  
							pointer-events: none;
							cursor: default; 
						}
					}
				}
				&.next{
					background-image: url("#{$images_path}chevron-sprites.png");
					background-size: 99px 100px;
					background-position: -57px 0;
					
					&.disabled {		
						opacity: 0.5; 
						 			
						A {
							opacity: 0.5;  
							pointer-events: none;
							cursor: default; 
						}
					}
				}
				&.active{
					color: $swatch-white;
					background-color: $swatch-satin-blue;
					
					A {
						color: $swatch-white;
					}
					
				}
				&:hover {
					color: $swatch-white;
					background-color: $swatch-satin-blue;
					A {
						color: $swatch-white;
					}
				}
				&.disable {
					
				}
				
			}
		}
	}
}
