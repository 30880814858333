$assets_domain:"/";
$images_path:"/assets/images/";
$fonts_path:"/assets/fonts/";

body {
	min-width: 1040px;
}


@import "mixins";
@import "reset";

@import "fonts";
@import "global";
@import "buttons";
@import "inputs";
@import "datepicker";
@import "timepicker";
@import "nav";
@import "hero";
@import "contents";
@import "loanrequest";
@import "user";
@import "footer";
@import "overlay";
@import "loading";
@import "pegination";
@import "printing";
@import "progressbar";

@import "products";
@import "summary";
@import "admin";