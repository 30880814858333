.profile-box {

	.inner-profile-box {
	
		.profile-details {
			min-width: 300px;
			
			display: table-cell;
			vertical-align: top;
			text-align: left;
			width: 33%;
			padding: 20px;
			border-right: 2px solid #FFF;
						
			&:last-child {
				border-right: 0;
			}
			
			p {
				line-height: 24px;
				word-break: break-all;
				@include SSTFontFamily( 'normal', 16px );
			}
		}
	}
}


.login-box {
	margin: 0 auto;
	max-width: 500px;

	.login-box-body {
		padding: 20px;
	}
}