.head-steps {
	display: block;
	color: $swatch-highlight-glow;
	
	.head {
		margin-right: 30px;
		@include SSTFontFamily( 'bold', 16px);
	
	}
	
	ul{
		display: inline-block;
	
		li{
			display: inline-block;
			padding-left: 30px;
			margin-right: 20px;
			background-image: url("#{$images_path}step-icon.png");
			background-repeat: no-repeat;
			background-position: 0 6px;
			
			&:first-child{
				padding-left: 0;
				background: none;
			}
			&:last-child{
				margin-right: 0;
			}
		
			&.active {
				color: $swatch-white;
			}
		}
	}
}


.products-box {

	
	H2 {
		margin-bottom: 20px;
	}
	
	p.sub-heading {
		margin-bottom: 30px;
	}

	.form-head {
		
		.main-head {
			@include SSTFontFamily( 'bold', 16px);
			position: relative;
			overflow: hidden;
			
			h5 {
				margin: 0;
			}
			
			.product-added-message {
				padding: 23px;
				width: 250px;
				position: absolute;
				top:0;
				right:0;
				line-height: 1.2em;
				
			}
		}
	}
	.form-box {
		padding: 20px;
	
		.input-block {
			display: block;
			clear: both;
			overflow: hidden;
			margin-bottom: 20px;
			padding-bottom: 20px;
			border-bottom: 1px solid $swatch-white;
		
			LABEL {
				display: block;
				clear: both;
			}
			> SPAN {
				display: block;
				clear: both;
				margin: 10px;
			}
			INPUT {
			
			}
			
			.enter-reference-color-style,
			.enter-reference-purchase-date {
				padding: 20px 0;
			}
			
		
			&.serial-info {
				padding-bottom: 0; 
				.enter-serial-wrappers {
					.input-label-wrapper {
						display: table-row;
						> .icon,
						> .label-container {
							display: table-cell;
						}
						> .label-container {
							padding-left: 20px;
						}
					}
				}
				
				.product-fixheight {
					height: 40px;
				}
				
			/* 
				.column {
					width: 47%;
					
					&:first-child {
						margin-right: 20px;
					}
					&:last-child {
						margin-left: 20px;
					}
				}
			*/
			}
			&.accidental {
				padding-bottom: 10px;
			
			}
			&.disk-stuck {
				padding-bottom: 0;
			
			}
			
			.icon-box{
				width: 70px;  
				min-width: 70px;
			}
			
			.input-box {
				width: 85%;
				
				.accidental-info {

					> A,
					> DIV {
						display: table-cell
						/*float: left;*/
					}
					> A {
						padding-right: 20px;
						
					}
					
					
				}
				.accidental-input {

				
				}
				.disk-stuck-info {
				
				}
				.disk-stuck-input {
					display: block;
					
					.column {
						/*display: inline-block;
						float: left;*/
						position: relative;
						min-width: 100px;
						padding-left: 50px;
					}
					.disk-nostuck-details {
						
						P{
							@include SSTFontFamily( 'bold', 13px);
						}
					}
					
					.disk-stuck-details {
						
						P{
							@include SSTFontFamily( 'bold', 13px);
						}
					}
					
					.checkbox-padding {
						margin: 10px 0;
					}
				}
				
			}
			.purchase-date-box {
				
				p {
					@include SSTFontFamily( 'normal', 16px);
					margin-bottom: 20px;
				}
				
				SPAN.title {
					@include SSTFontFamily( 'bold', 16px);
					display: inline-block;
					margin-top: 10px;
					&:after {
						content: " ";
					}
				}
				
			}
		
			&:first-child {
			
			}
			&:last-child {
				border: 0;
			}
		}
	}
	
	&.step2 {
		h5 {
			line-height: 50px;
		}
		p.heading {
			line-height: 50px;
			@include SSTFontFamily( 'bold', 18px);
		}
		
	}
	
	UL.list-product-types {
		display: table;
		width: 100%;
		
		LI {
			display: table-cell;
		}
	}
}



.productslist-box {

	&.outofwarranty-box {
		padding-top: 50px;	
	}
	> h5,
	> P,
	> .item-nums {
		margin-bottom: 10px;
	}
	
	.list-products {
		.products-container {
			> UL {
				display: table;
				width: 100%;
			
				> LI{
					display: table-row;
					width: 100%;
					
					&:nth-child(even) {
						background-color: $swatch-mid-silver-white;
					}
					&:nth-child(odd) {
						background-color: $swatch-white;
					}
					
					&.inactive {
						opacity: .3;
					}
					&.status_created {
						
					}
					&.status_in_process {
						color: #8a6d3b;
			    		background-color: #fcf8e3;
			    		border-color: #faebcc;
					}
					&.status_approved {
					    color: #3c763d;
					    background-color: #dff0d8;
					    border-color: #d6e9c6;
					}
					&.status_not_approved {
						color: #a94442;
					    background-color: #f2dede;
					    border-color: #ebccd1;
					}
					&.status_closed {
						background: rgba(66, 66, 203, 0.2);
						opacity: 0.7;
					}
					&.pending_approval {
						color: #808000;
						background-color: #FFFF99 !important;
						border-color: #faebcc;
					}
					&.head {
						background-color: $swatch-black;
						color: $swatch-white;
						border-right: 2px solid $swatch-white;
						
						.p-list {
							@include SSTFontFamily( 'bold', 16px);
							text-transform: uppercase;
						}
					}
					
					.p-list {
						position: relative;
						display: table-cell;
						border-right: 2px solid #fff;
						border-bottom: 2px solid #fff;
						padding: 20px;
						/*max-width: 170px;*/
						min-width: 50px;

						overflow-wrap: break-word;
						word-wrap: break-word;
					 	-ms-word-break: break-all;
						word-break: break-word;
						-ms-hyphens: auto;
						-moz-hyphens: auto;
						-webkit-hyphens: auto;
						hyphens: auto;
  

  						&.rr-product,
  						&.s-product {
  							min-width: 125px;
  							max-width: 175px;
  						}
				
  						&.no-border {
  							border-right: 0;
  						}

						UL.p-details {
							display: block;
							/*min-width: 300px;*/
							
							> LI {
								display: block;
								line-height: 22px;					
							}
						
						}


						.counter {
						    content: counter(li, decimal);
						    counter-increment: li;
						    font-family: $font-family-bebas;
						    font-size: 32px;
						    left: 20px;
						    margin-right: 8px;
						    position: absolute;
						    text-align: left;
						    top: 30px;
						    width: 35px;
						
						}
						&:first-child{
							
						
						}
						&:last-child {
							border-right: 0;
							width: 120px;
						}
					}
				}
			}
		}
	}
}

.returns-review,
.step2{
		.outofwarranty-input {
			.error-message {
				text-align: left;
				top: -10px;
				
				.triangle {
					left: 15px;
				}
			}
		}
		.pickupdate {
			position: relative;
			max-width: 160px;
			margin: 0 auto;
			
			.error-message {
				text-align: left;
				width: 250px;
			}
		}
}



.step1 {

	.purchasedate2 {
		position: relative;
		max-width: 160px;
	}
	.productslist-box {
		.list-products {
			.products-container {
				> UL {
					> LI{
						.p-list {
							UL.p-details {
								padding-left: 40px;
							}
						}
					}
				}
			}
		}
	}
}

.summary {
	&.productslist-box {
		.list-products {
			min-height: 600px;
			.products-container {
				> UL {
					> LI{
						.p-list {
							UL.p-details {
								
							}
						}
					}
				}
			}
		}
	}
}

.productslist-wrapper {
	width: 100%;

	display:table;
	border-collapse:separate;
	border-spacing:5px;

	.productslist-inner-wrapper {
		display: table-row;

		h2 {
			color: #363636;
		}
		h5 {
			background: #363636;
			color: #fff;
			text-transform: uppercase;
			padding: 10px;
			margin: 0;
		}

		.movable {
			display: table-cell;
			margin-right: 1%;
			min-width: 160px;
			background: #f5f5f5;

			.inner-container {
				min-height: 500px;
			}

			&.product-options {

				.product-options-inner-container {
					display: table-row;

					.listbox {
						display: table-cell;
						min-width: 110px;
						min-height: 448px;

						&:first-child {
							border-left: 0;
						}

						UL {
							margin: 0;
							padding: 0;
						}
					}
				}
			}

			.listbox {
				display: block;

				UL.list {
					displya: block;
					padding: 5px 0;
					LI {
						display: block;
						padding: 10px;
						background: #eee; 
						margin-bottom: 5px;
						cursor: pointer;
						position: relative;

						.delete-icon {

							position: absolute;
							top: -2px;
							right: -3px;
							width: 12px;
							height: 12px;
							background: url("#{$images_path}close_icon.png");
							background-repeat: no-repeat;
							background-position: center center;
							background-size: 12px 12px;
						}
					&.inactive {
						opacity: .3;
					}
					&.status_created {
						
					}
					&.status_in_process {
						color: #8a6d3b;
			    		background-color: #fcf8e3;
			    		border-color: #faebcc;
					}
					&.status_approved {
					    color: #3c763d;
					    background-color: #dff0d8;
					    border-color: #d6e9c6;
					}
					&.status_not_approved {
						color: #a94442;
					    background-color: #f2dede;
					    border-color: #ebccd1;
					}
					&.status_closed {
						background: rgba(66, 66, 203, 0.2);
						opacity: 0.7;
					}
					&.pending_approval {
						color: #808000;
						background-color: #FFFF99 !important;
						border-color: #faebcc;
					}

						&:hover,
						&.selected {
							background: #0068bf none repeat scroll 0 0;

							SPAN,
							DIV {

								color: #FFF;
							}

							.delete-icon {
								background: url("#{$images_path}close_icon_hover.png");
								background-repeat: no-repeat;
								background-position: center center;
								background-size: 12px 12px;

							}
						}

						&.selected {
							font-weight: bold;
						}
						&.subitems {
							font-weight: bold;
						}
						SPAN {

						}


					}
				}

			}

		}
	}


	.addnew {


		A {
		    color: #ffffff;
		    cursor: pointer;
		    padding: 10px;
		    display: block;
		    font-family: "SST W01 Bold","SST W02 Bold","SST W10 Bold","SST W15 Bold","Arial Bold",Helvetica,Arial;
		    font-size: 16px;
		    font-weight: normal;
		    min-width: 40px;
		    text-align: center;
		    text-decoration: none;
		    text-transform: uppercase;
			background-color: #e55526;
		    background-image: -moz-linear-gradient(center top , #f96f38, #e55526);

		    &:hover {
		   		color: #ffffff;
		    }
		}
	}
}

.non-default-values {
	max-height: 310px;
	overflow-y: scroll;

	LABEL {
		text-transform: uppercase;
	}
	.column {
		display: inline-block;
		min-width: 400px;
		float: left;
	}
}

/* OVERLAY MODAL */

* {
    box-sizing: border-box;
}
.overlay-container H2 {
	margin: 0 0 5px 0;
}
A.overlay-close {
	color: #fff;
	float: right;
	margin-top: -40px;
	border-radius: 0;
}
A.btn:hover {
	color: #fff;
}
.edit .input-group {
	display: block;
	padding: 10px 20px;

}
.error-message {
	margin-bottom: 15px;
	color: #c72928;
}
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
}

.edit .modal-container {
    width: 940px;
    margin: 150px auto 0;
    padding: 20px 30px;
    border-radius: 2px;
    transition: all .3s ease;
}

.overlay-container {
    background-color: #fff;
}

.modal-enter, .modal-leave {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

