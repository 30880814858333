NAV {
	background-color: $swatch-black;
    overflow: visible;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999999;
    display: block;
    
    .top-logo{
    	height: 35px;
		background-image: url("#{$assets_domain}assets/images/sony_logo.png");
		background-position: right center;
		background-repeat: no-repeat;
    }
		
    .main-nav-container {
    	display: block;
    	overflow: hidden;
    	height: 64px;
    	@include background-color($swatch-black, #2b2b2b);
    	/*
		background-image:        linear-gradient(top, #2b2b2b, $swatch-black);
		*/
		UL {
			display: inline-block;
			float: left;
			
			LI{
				display: table-cell;
				padding: 0 30px;
				text-align: center;
				vertical-align: middle;
				
				A {
					@include SSTFontFamily( 'bold', 14px );
    				line-height: 64px;
    				text-decoration: none;
    				
    				SPAN{
    					display: block;
    					color: $swatch-white;
						text-transform: uppercase;
						
	    				&.ps-home {
							background-image: url("#{$assets_domain}assets/images/ps_logo.png");
						    background-position: left center;
						    background-repeat: no-repeat;
						    display: table-cell;
						    height: 39px;
						    width: 50px;
	    				}
	    			}
				}

				&.logout{
					A {
						SPAN {
							color: $swatch-highlight-glow;
						}
					}
				}
			}
			&.sec-nav-list {
				float: right;
				
				LI{
					&:hover {
						color: $swatch-white;
						background: none repeat scroll 0 0 $swatch-satin-blue;
						
						transition: all 200ms ease-in-out;
						
						&.logout{
							A {
								SPAN {
									color: $swatch-white;
								}
							}
						}
						
					}
				}
			}
			
			&.main-nav-list {
						
				LI {

					&:first-child {
						padding-right: 0;
					}
				}
			}
		}
		
    }    
}