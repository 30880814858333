section.hero {
	overflow-x: hidden;
}

HEADER {
	position: relative;
	height: 336px;
	margin-top: 64px;
	background-image: url("#{$images_path}hero.jpg");
	background-size: cover;
	.wrap {
		display: block;
		z-index: 2;
		color: #FFF;
	}
	

	.wrap{
		position: relative;
	

		.hero-heading {
			position: absolute;
			top: 50px;
			left: 50px;
			width: auto;
			
			.hero-heading-wrapper {
				display: table;
				text-align: left;
				
				.main-heading,
				.sub-heading {
					padding: 20px;
					
					background-color: transparent;
					color: #fff;
					display: block;
					float: left;
					margin: 0;
				    pointer-events: auto;
				    position: relative;
				    text-align: left;
				    text-decoration: none;
				    z-index: 1;
					
					&:before{
					    background-color: $swatch-text-grey;
						bottom: 0;
						content: "";
						display: block;
						left: 0;
						opacity: 0.5;
						position: absolute;
						right: 0;
						top: 0;
						transition: opacity 0.3s ease-in-out 0s;
						z-index: -1;
	    			}
	    			
					h1, h2, h3, h4, p {
						margin: 0;
					}
				}
				.sub-heading {
					margin-left: 60px;
					    clear: left;
					    position: relative;
				}
			}
		}
	}
	
}