/* Overlay style */
.overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0,0,0,0.6);
	z-index: 999999;

	.overlay-wrapper {
		max-width: 680px;
		background: $swatch-white;
		min-height: 200px;
		margin: 0 auto;
		/*margin-top: 15%;*/
		position: relative;
		top: 300px;

		.overlay-container{
			display: block;
			border: 2px solid;
			.bar {
				overflow: hidden;
			}


			.text,
			.img {
				display: table-cell;
				vertical-align: top;

				IMG {
					margin: 0 10px;
				}
			}

			H2 {
				margin-bottom: 5px;

			}

			H5 {
				margin-bottom: 5px;
			}

		}
		.overlay-close {
			width: 40px;
			height: 40px;
			position: absolute;
			right: 0;
			top: 0px;
			overflow: hidden;
			border: none;
			color: transparent;
			outline: none;
			z-index: 100;
		}
	}

}

/* Effects */
.overlay-scale {
	visibility: hidden;
	opacity: 0;
	/*
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
	-webkit-transition: -webkit-transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
	transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
	*/
}

.overlay-scale.open {
	visibility: visible;
	opacity: 1;
	/*
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.4s;
	*/
}
