.ui-loader-horizontal {
        opacity: 0;
        position: relative;
        visibility: hidden;
		display: none;
		
        &.visible {
        	display: block;
            opacity: .6;
            visibility: visible;
        }
        
        .svg-container {

        }

         path {
            opacity: .2;
            animation: fade-in-out 0.8s infinite ease-in-out;

            &.ps-square { animation-delay: .0s; }
            &.ps-triangle  { animation-delay: .6s; }
            &.ps-x { animation-delay: .2s; }
            &.ps-circle { animation-delay: .4s; }
        }
    }

.ui-loader {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: 200;
    top: 50%;
    left: 50%;
    margin-left: -150px;
    margin-top: -80px;
    background-color: rgba( 31, 31, 31, 0.9 );
    box-shadow: 1px 3px 2px 0 rgba( 0, 0, 0, 0.2 );
    width: 300px;
    height: 160px;
    transition: opacity 0.3s ease-in;
    
    &.visible {
        opacity: 1;
        visibility: visible;
    }
    
    .loading {
        position: relative;
        padding-left: 125px;
        margin-top: 35px;
        width: 52px;
        height: 50px;
        
        svg {
            width: 52px;
            height: 50px;
        }
        
        path {
            opacity: .2;
            animation: fade-in-out 0.8s infinite ease-in-out;
            -webkit-animation: fade-in-out 0.8s infinite ease-in-out;
            
            &.ps-square { animation-delay: 0; -webkit-animation-delay: 0; }
            &.ps-triangle  { animation-delay: .2s; -webkit-animation-delay: .2s; }
            &.ps-x { animation-delay: 0.6s; -webkit-animation-delay: 0.6s; }
            &.ps-circle { animation-delay: 0.4s; -webkit-animation-delay: 0.4s; }
        }
    }
    
    span {
        padding-top: 30px;
        display: block;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        font-size: 16px;
    }
}


@keyframes fade-in-out {
    0%, 80%, 100% {
        opacity: .2;
    }

    40% {
        opacity: 1;
    }
}