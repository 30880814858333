
.request-container {

	UL {
		display: block;

		LI {
			display: block;

		}
	}
	
	.block {
		display: block;
		padding: 20px 0;
		margin-bottom: 20px;

		.inner-block {
			display: inline-block;
			min-width: 200px;
			max-width: 300px;
			padding: 30px;
			min-height: 50px;
			background: #f5f5f5;
			cursor: pointer;
			margin-right: 2px;

			&:hover {
				background: #0068bf;
				color: #FFFFFF;
			}
			&.selected{
				background: rgba(0, 0, 0, 0) linear-gradient(to bottom, #004ba3 0%, #0068bf 100%) repeat scroll 0 0;
				box-shadow: 0 0 10px #333 inset;
				color: #fff;
			}
		}
	}

	.delivery {


		H2 {

			margin-top: 40px;
		}
	}

	.input-group {
		margin: 0 7px;

		LABEL {
			text-align: left;
			margin: 10px 0 10px 0;
			display: block;
		}
		INPUT, TEXTAREA, SELECT {

		}

	}

	.products {
		text-align: left;

		.products-categories {
			margin: 30px 0;
			
			
			H2{
				text-align: center;
			}
	
			H3 {
				background: #363636;
				color: #fff;
				padding: 10px;
			}
	
			
			.products-list {
				
				
				.option {
					margin-top: 10px;
					vertical-align: top;
				}
			
				.option2,
				.option3 {
					display: inline-block;
					width: 50%;
				}
				
				.products-item {
					
					h4 {
						margin-top: 20px;
					}
					
					TEXTAREA {
						margin-top: 10px;
					}
				}
				
			}
		}

	}

}


.help-popup {
	
	display: block;
	width: 325px;
	position: fixed;
	bottom: 5px;
	right: 5px;
	border: 1px solid #f5f5f5;
	background: #fff;
	padding: 20px;
	H2 {
		text-align: center;
		
	}
	p {
		
		
	}
	.cross {
		position: absolute;
		top: 0;
		right: 0;
		font-weight: bold;
		background: red;
		color: #fff;
		padding: 5px;
		height: 30px;
		width: 30px;
		text-align: center;
	}
}