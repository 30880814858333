#pdc-footer {
    $footer-top-row-height: 70px;

    position: relative;
    z-index: 20;
    padding: 0 30px 30px;
    background: #01176b url( "#{$images_path}glow.png" ) top center;
    text-align: left;

    &.no-top-row {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    a {
        text-decoration: none;
    }
    

	.footer-top {
		display: block;
		overflow: hidden;
		
		clear: both;
		margin-left: -30px;
		margin-right: -30px;
		padding: 15px 30px;
		z-index: 21;
        border-bottom: 2px solid rgba( 255, 255, 255, 0.1 );
        
        .select-lang,
        .back-to-top {
        	cursor: pointer;
        	display: inline-block;
        	float: left;
        	color: $swatch-white;
        	@include SSTFontFamily( 'bold', 16px );
        	
        }
        .back-to-top {
        	float: right;
        	line-height: 40px;
        	
        	.text {
        	  	padding-right: 10px;
        	}
        }
        
        .select-lang {
        
        	.text {
        		display: inline-block;
        		color: #8fd5fd;
        		@include SSTFontFamily( 'normal', 14px );
        		margin-bottom: 3px;
        	}
        	
        	.langs {
        		A {
        			color: $swatch-white;
        			@include SSTFontFamily( 'bold', 14px );
        		}
        	}
        
        }
	}


    .flags {

        display: inline-block;
        height: 12px;
        //@include spriteMonoIcons();

        &.en_GB {
            width: 83px;
            background-position: 0px -5023px;
        }

        &.de_DE {
            width: 83px;
            background-position: 0px -5045px;
        }

        &.fr_FR {
            width: 83px;
            background-position: 0px -5067px;
        }

        &.es_ES {
            width: 14px;
            background-position: 0px -5089px;
        }

        &.it_IT {
            width: 37px;
            background-position: 0px -5110px;
        }

        &.el_GR {
            width: 14px;
            background-position: 0px -5132px;
        }

        &.nl_NL {
            width: 37px;
            background-position: 0px -5154px;
        }

        &.pl_PL {
            width: 14px;
            background-position: 0px -5175px;
        }

        &.pt_PT {
            width: 14px;
            background-position: 0px -5198px;
        }

        &.ru_RU {
            width: 14px;
            background-position: 0px -5220px;
        }

        &.tr_TR {
            width: 14px;
            background-position: 0px -5242px;
        }

        &.en_AE {
            width: 14px;
            background-position: 0px -5264px;
        }

    }

    #footer-top-row {
        @include SSTFontFamily( 'bold', 14px );
        margin: 0 -30px 40px;
        padding: 0 30px;
        height: $footer-top-row-height;
        border-bottom: 2px solid rgba( 255, 255, 255, 0.1 );

        #current-language {
            transition: opacity 0.3s linear;
            float: left;
            text-transform: uppercase;
            color: #fff;
            line-height: $footer-top-row-height;
            padding-right: 10px;

            &:hover {
                opacity: 0.8;
            }

            span.arrow {
                position: relative;
                top: 1px;
                display: inline-block;
                //@include sprite( 0px -359px, 10px, 13px );
            }

            span.flags {
                position: relative;
                top: 1px;
                left: 10px;
            }

            strong {
                display: inline-block;
                margin-left: 5px;
                color: #8fd5fd;
            }
        }

        #back-to-top {
            transition: opacity 0.3s linear;
            float: right;
            color: #fff;
            text-transform: uppercase;
            line-height: $footer-top-row-height;

            span {
                position: relative;
                top: 5px;
                display: inline-block;
                margin-left: 13px;
                //@include sprite( -50px -250px, 18px, 23px );
            }

            &:hover {
                opacity: 0.8;
            }
        }
    }

    #language-selector {
        margin: -40px -30px 40px;
        background-color: rgba( 0, 0, 0, 0.1 );
        transition: all 0.4s ease-in;
        max-height: 0;
        opacity: 0.5;
        overflow: hidden;

        #language-selector-content {
            padding: 30px;
        }

        &.opened {
            max-height: 150px;
            opacity: 1;
            transition: all 0.4s ease-out;
        }

        ul {
            max-width: 1000px;
            margin-top: -20px;
            margin-left: -30px;
            list-style: none;
            overflow: hidden;

            .current a {
                color: #8fd5fd;
            }

            a {
                @include SSTFontFamily( 'bold', 14px );
                transition: color 0.3s linear;
                text-transform: uppercase;
                color: #fff;

                span {
                    display: block;
                    margin-top: 5px;
                    transition: opacity 0.3s linear;
                }

                &:hover {
                    color: #8fd5fd;

                    span {
                        opacity: 0.8;
                    }
                }
            }

            li {
                margin-left: 30px;
                margin-top: 20px;
                float: left;
                width: 120px;
            }
        }
    }

    .clear {
        clear: both;
    }

    #footer-legal {
        float: left;
        max-width: 840px;
        width: 80%;

        #footer-logos-small {
            float: none;
            text-align: left;
            display: none;
            margin-top: 25px;
            margin-bottom: 25px;
        }
    }

    .footer-logos {
        float: right;
        width: 180px;
        text-align: right;

        .footer-logo-ask-about-games {
            position: relative;
            top: 4px;
            display: inline-block;
            background-image: url(#{$images_path}main.png);
            background-repeat: no-repeat;
            background-position: 0 -100px;
            width: 80px;
            height: 65px;
        }

        .footer-logo-sony {
            display: inline-block;
            margin-left: 30px;
            background-image: url(#{$images_path}main.png);
            background-repeat: no-repeat;
            background-position: -10px -4855px;
            width: 47px;
            height: 69px;
        }

        .footer-logo-lithium {
            clear: both;
            display: inline-block;
            margin-top: 85px;
            //@include sprite( 1px -798px, 111px, 21px );
        }
    }

    #footer-links {
        //margin-top: 50px;

        #footer-ps-logo {
            position: relative;
            top: 8px;
            display: inline-block;
            background-image: url(#{$images_path}main.png);
            background-repeat: no-repeat;
            background-position: 0 0;
            width: 49px;
            height: 38px;
        }

        ul {
            list-style: none;
            overflow: hidden;
            padding-top: 20px;
            @include SSTFontFamily( 'bold', 14px );
            
            display: block;
		    margin-left: -30px;
		    margin-right: -30px;
		    padding-left: 30px;
		    padding-right: 30px;
		    padding-top: 20px;
        }

        li {
            float: left;
            margin-top: 10px;
			line-height: 65px;
            &:after {
                display: inline-block;
                content: '|';
                color: #fff;
                @include SSTFontFamily( 'normal', 14px );
                margin: 0 10px;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
            
            &:first-child {
            	margin-right: 20px;
            	
                &:after {
                    display: none;
                }
            }
        }

        a {
            color: #fff;
            transition: color 0.3s linear;

            &:hover {
                color: #8fd5fd;
            }
        }
    }

    #footer-disclaimer {
        margin-top: 30px;
        color: #8fd5fd;

        p {
            line-height: 1.4em;
        }

        a {
            color: #8fd5fd;

            &:hover {
                color: lighten( #8fd5fd, 10% );
            }
        }
    }
}
