.admin-nav {
	display: block;
	width: 100%;


	.top-nav,
	.sub-nav {
		display: table;
		width: 100%;

		.nav-item-container {
			display: table-cell;
			padding-right: 10px;

			&:last-child {
				padding-right: 0;
			}

			SPAN {

				A{
					background: #eeeeee;
					width: 100%;
				 	color: #363636;
				    cursor: pointer;
				    display: block;
				    font-family: "SST W01 Bold","SST W02 Bold","SST W10 Bold","SST W15 Bold","Arial Bold",Helvetica,Arial;
				    font-size: 16px;
				    font-weight: normal;
				    min-width: 100px;
				    position: relative;
				    text-align: center;
				    text-decoration: none;
				    text-transform: uppercase;
				    padding: 20px 30px;

					-webkit-transition: all 0.5s ease;
					transition: all 0.5s ease;

					&:hover,
					&:focus,
					&.selected {
						background: #0068bf;
						color: #fff;

					}

				}
			}
		}
	}

	.sub-nav {
		margin-top: 20px;


		.nav-item-container {
			SPAN {

				A{
					padding: 20px 30px;

				}
			}
		}
	}

	&.activated {

		.nav-item-container {
			SPAN {

				A{
					padding: 20px 30px;
				}
			}
		}

	}

}