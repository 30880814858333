/* ===============================
 * INPUT STYLES
 * ===============================*/

SELECT,
INPUT[type="text"],
INPUT[type="password"],
TEXTAREA{
	@include SSTFontFamily( 'normal', 16px, 22px );
	letter-spacing: -0.2px;
    cursor: auto;
    color: #363636;
    border: 1px solid #e6e6e6;
    outline: medium none;
    width: 100%;
    padding: 9px;
    border-radius: 0;
    box-sizing: border-box;
    display: inline-block;
    
    &:focus {
    	border: 1px solid #66afe9;
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
		outline: 0 none;
    }
    &.error {
		color: #c90101;
	}
}
TEXTAREA {
	min-height: 100px;
}
SELECT {
	height: 42px;

	OPTION {
		padding: 5px 10px;
	}
}

LABEL {
	@include SSTFontFamily( 'bold', 16px );

	&.text{
		padding-bottom: 10px;
		clear: both;
		display: block;
	}
}
.input-group {
	position: relative;
	clear: both;
	margin-bottom: 20px;
	
	&:last-child {
		margin-bottom: 0;
	}
	
	
	INPUT[type="checkbox"] {
	    float: left;
	    z-index: 2;
	    position: absolute;
	    left: 0;
	    height: 40px;
	    width: 40px;
	    opacity: 0;

	     &.error {
			+ label {
				color: #c90101;
			}
		}
		+ label {
			/*@include SSTFontFamily( 'normal', 16px );*/
		    display: block;
		    float: left;
		    margin: 0 14px 0 0;
		    padding: 0;
		    position: relative;
		    padding-left: 14px;
		    /*line-height: 40px;*/
		    padding-left: 60px;
		}
		+ label:before {
	    	z-index: 1;
		    background: none repeat scroll 0 0 $swatch-mid-silver;
		    border: 1px solid $swatch-white;
		    content: "";
		    cursor: pointer;
		    display: block;
		    height: 40px;
		    left: 0px;
		    position: absolute;
		    width: 40px;
		}
		
		&:checked + label:before {
	    	/*background: none repeat scroll 0 0 #363636;*/
			background-image: url("#{$images_path}icons-sprites.png");
			background-repeat: no-repeat;
			background-position: -67px -249px;
			background-size: 98px 900px;
	    	
	    }
		
		&:disabled {
			+ label:before {
				background: none repeat scroll 0 0 $swatch-mid-silver;
				border: 1px solid $swatch-mid-silver-dark;
			}
			
			&:checked + label:before {
				background-image: url("#{$images_path}icons-sprites.png");
				background-repeat: no-repeat;
				background-position: -67px -249px;
				background-size: 98px 900px;
	    	}
		}
	    
	    &.grey{
	    	+ label:before {
	    		background: none repeat scroll 0 0 #eee;
	    		border: 9px solid #eee;
	    	}
	    }
	}
	
	INPUT[type="radio"] {
		
	    background: none repeat scroll 0 0 #fff;
	    float: left;
	    z-index: 2;
	    position: absolute;
	    left: 0;
	    height: 40px;
	    width: 40px;
	    opacity: 0;
	    
	     &.error {
			+ label {
				color: #c90101;
			}
		}
		
	   	+ label {
		    display: block;
		    float: left;
		    margin: 0 14px 0 0;
		    padding: 0;
		    position: relative;
		    padding-left: 14px;
		    line-height: 40px;
		    padding-left: 20px;
		}
		+ label:before {
		    border-radius: 40px;
		   	z-index: 1;
		    background: none repeat scroll 0 0  $swatch-mid-silver;
		    border: 9px solid #fff;
		    content: "";
		    cursor: pointer;
		    display: block;
		    height: 40px;
		    left: -40px;
		    position: absolute;
		    width: 40px;
		}
		&:checked + label:before {
	    	background: none repeat scroll 0 0 #363636;
	    }
	    
	    &.grey{
	    	+ label:before {
	    		background: none repeat scroll 0 0 #eee;
	    		border: 9px solid #eee;
	    	}
			&:checked + label:before {
		    	background: none repeat scroll 0 0 #363636;
		    }
	    }
	}


	
	
	&.error {
		/*color: RED;*/
		LABEL,
		INPUT[type="text"],
		INPUT[type="password"] {
			color: RED;
			/*
			background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAANCAMAAACXZR4WAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Qzg1MEQ5RDIwNDRDMTFFNThDMzg5QzQzQ0NFRDZFMTciIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Qzg1MEQ5RDMwNDRDMTFFNThDMzg5QzQzQ0NFRDZFMTciPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDODUwRDlEMDA0NEMxMUU1OEMzODlDNDNDQ0VENkUxNyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDODUwRDlEMTA0NEMxMUU1OEMzODlDNDNDQ0VENkUxNyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PpT2R6gAAACEUExURQAAAQAAAQAAAQAAAQAAAQAAAQAAAQAAATY2NjY2NgAAATY2NgAAAQQEBQEBAgICAwMDBAICAwMDBAAAAQYGBwgICQkJCgsLDDExMR0dHR4eHzQ0NCsrKywsLC0tLS4uLi4uLi8vLzAwMDAwMTExMTExMTExMTY2NjY2NjU1NTY2NjY2NrhBJfQAAAArdFJOUwABAgMFExUWJSYpKSstLzIyMzU5PEBCR1F3fJOssre8vcLLztDS1eXp+Poa2rjVAAAAa0lEQVQI11XORxbCQAwE0RImD9kmB5vgAH3/+7HAeoO16l8rAUb3bDltVz8dALbKLzMAkv3rOIR5Ll0XBsmu0vs04ixJt7X1tqWkz4HQSFKxyZ6SVI9py/3h9hIdi9tL9K/Uk/93Q9M1BPcXBNYNwckgDCIAAAAASUVORK5CYII=");
			background-position: 98% center;
			background-repeat: no-repeat;
			*/
		}
	}
	
	.error-message {
	    background: none repeat scroll 0 0 #c72928;
	    box-shadow: 2px 2px 10px 0 rgba(50, 50, 50, 0.48);
	    color: #fff;
	    font-size: 12px;
	    margin-bottom: 0;
	    margin-top: 0;
	    max-width: 300px;
	    padding: 10px;
	    position: relative;
	    width: 100%;
	    opacity: 0;
	    display: none;
	    z-index: 3;
	}
	
	.custom-checkbox .error-message {
	    min-width: 300px;
	    z-index: 100;
	}
	
	.error-message .triangle {
	    border-color: transparent transparent #c72928;
	    border-style: solid;
	    border-width: 0 8.5px 8px;
	    height: 0;
	    left: 20px;
	    position: absolute;
	    top: -8px;
	    width: 0;
	}
	
	&.error {
		.error-message {
			display: block;
			opacity: 1;
		}
	}
	.animate-me {
		transition: all 300ms cubic-bezier(0.25, 0.25, 0.75, 0.75) 0s;
	}
	
	span.help {
		display: block;
		@include SSTFontFamily( 'normal', 14px );
		margin: 5px 0 15px 0;
		
		A{
			@include SSTFontFamily( 'normal', 14px );
		}
	}
	
	div.help {
		margin-top: 5px;
		
		A {
			@include SSTFontFamily( 'normal', 14px );
		}
	}
}
.checkbox-padding {
	padding: 10px 0;
	margin-bottom: 40px;
}

.hasDatepicker,
.datepicker {
	background-image: url("#{$images_path}icon-calendar.png");
	background-position: right center;
	background-repeat: no-repeat;
}

/* ===============================
 * Custom style for inputs
 * ===============================*/
 
 .lang_en .input-group INPUT[type="checkbox"] + label {
 	line-height: 40px;
 }
 
 .search-serial {
 	min-width: 230px;
	
	& + .error-message {
 		max-width: 230px;
 	}
 }
 .serial-type {
 	width: 150px;
 }
 
 .default-errors {
 	margin-bottom: 20px;
 	text-align: center;
 	color: #c90101;
 }
 
/* ===============================
 * BROWSER SPECIFIC INPUT STYLES (with JS which adds broswer name as class to HTML)
 * ===============================*/

.safari SELECT,
.chrome SELECT{
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAANCAMAAACXZR4WAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Qzg1MEQ5RDIwNDRDMTFFNThDMzg5QzQzQ0NFRDZFMTciIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Qzg1MEQ5RDMwNDRDMTFFNThDMzg5QzQzQ0NFRDZFMTciPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDODUwRDlEMDA0NEMxMUU1OEMzODlDNDNDQ0VENkUxNyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDODUwRDlEMTA0NEMxMUU1OEMzODlDNDNDQ0VENkUxNyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PpT2R6gAAACEUExURQAAAQAAAQAAAQAAAQAAAQAAAQAAAQAAATY2NjY2NgAAATY2NgAAAQQEBQEBAgICAwMDBAICAwMDBAAAAQYGBwgICQkJCgsLDDExMR0dHR4eHzQ0NCsrKywsLC0tLS4uLi4uLi8vLzAwMDAwMTExMTExMTExMTY2NjY2NjU1NTY2NjY2NrhBJfQAAAArdFJOUwABAgMFExUWJSYpKSstLzIyMzU5PEBCR1F3fJOssre8vcLLztDS1eXp+Poa2rjVAAAAa0lEQVQI11XORxbCQAwE0RImD9kmB5vgAH3/+7HAeoO16l8rAUb3bDltVz8dALbKLzMAkv3rOIR5Ll0XBsmu0vs04ixJt7X1tqWkz4HQSFKxyZ6SVI9py/3h9hIdi9tL9K/Uk/93Q9M1BPcXBNYNwckgDCIAAAAASUVORK5CYII=");
	background-position: 97% center;
	background-repeat: no-repeat;
	-webkit-appearance: none;
	-moz-appearance:    none;
	appearance:         none;
}

