@mixin breakpoint( $size, $type: 'max' )
{
    @media( #{$type}-width: $size )
    {
        @content;
    }
}

@mixin SSTFontFamily( $type, $fontSize: '', $lineHeight: '' )
{
    font-family: if( $type == 'normal', $font-family-normal, $font-family-bold );
    font-weight: normal;

    @if $fontSize != ''
    {
        font-size: #{$fontSize};
    }

    @if $lineHeight != ''
    {
        line-height: #{$lineHeight};
    }
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}


@mixin background-color($base, $overlay: '')
{	
		background-color:$base;
		@if $overlay != ''
		{
			background-image:-webkit-linear-gradient(top, $overlay, $base);
			background-image:-moz-linear-gradient(top, $overlay, $base);
			background-image:-ms-linear-gradient(top, $overlay, $base);
			background-image:-o-linear-gradient(top, $overlay, $base);
			background-image:linear-gradient(top, $overlay, $base);
		}
}