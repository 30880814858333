.ui-datepicker {
	z-index: 100 !important;
	width: 350px;
	padding: 20px;
	display: none;
	background: $swatch-white;
	@include SSTFontFamily( 'normal', 16px, 22px );
	
	.ui-datepicker-header {
		position: relative;
		padding: 0 0 20px 0;
		@include SSTFontFamily( 'bold', 18px, 22px );
	
		.ui-datepicker-title {
			line-height: 40px;
			text-align: center;
		}
		
		.ui-datepicker-prev,
		.ui-datepicker-next {
			position: absolute;
			width: 40px;
			height: 40px;
			
			
			span {
				display: block;
				overflow: hidden;
				cursor: pointer;
				width: 40px;
				height: 40px;
				
				text-indent: -100px;
				background-image: url("#{$images_path}chevron-sprites.png");
				backgroung-repeat: no-repeat;
				background-position: -1px 0px;
				background-size: 99px 100px;
			}
			
			&.ui-state-disabled {
				
				span {
					
					background-position: -1px -59px;
					cursor: auto;
				}
			}
				
		}
		.ui-datepicker-next {
			right: 0;
			
			span {
				background-position: -57px 0;
			
			}
			
			&.ui-state-disabled {
				
				span {
					
					background-position: -57px -59px;
				}
			}
		}
		
		.ui-datepicker-month,
		.ui-datepicker-year {
			max-width: 85px;
			padding: 5px;
			height: 36px;
			margin-right: 10px;
			@include SSTFontFamily( 'bold', 16px, 20px );
			background-position: 90% center !important;
		}
		.ui-datepicker-year {
			margin-right: 0;
		}
		
	}
	
	table {
		width: 100%;
		border-collapse: collapse;

		th {
			padding: 3px 0 5px 0;
			text-align: center;
			border: 0;
			@include SSTFontFamily( 'bold', 16px, 22px );
			background: $swatch-black;
			color: $swatch-white;
			
			border-right: 1px solid $swatch-white;
			
			&:last-child {
				border-right: 0;
			}
		}
		td {
			border: 0;
			padding: 1px;
		}
		td {
			border: 1px solid $swatch-mid-silver;
			text-align: center;
			vertical-align: middle;
			span,
			a {
				display: block;
				padding: 9px 5px;
				text-decoration: none;
				@include SSTFontFamily( 'bold', 16px, 22px );
				
				&.ui-state-active,			
				&.ui-state-hover {
					background-color: $swatch-satin-blue;
					color: $swatch-white;
				}
				
			}
			
			&.ui-state-disabled ,
			&.ui-datepicker-unselectable {
				color: $swatch-black;
				background-color: $swatch-light-silver;
					
				SPAN,
				A{
					color: $swatch-black;
					background-color: $swatch-light-silver;
				}
			}
		}
	}
}

/*
.ui-datepicker {
	width: 17em;
	padding: .2em .2em 0;
	display: none;
	z-index: 100 !important;
}
.ui-datepicker .ui-datepicker-header {
	position: relative;
	padding: .2em 0;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
	position: absolute;
	top: 2px;
	width: 1.8em;
	height: 1.8em;
}
.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
	top: 1px;
}
.ui-datepicker .ui-datepicker-prev {
	left: 2px;
}
.ui-datepicker .ui-datepicker-next {
	right: 2px;
}
.ui-datepicker .ui-datepicker-prev-hover {
	left: 1px;
}
.ui-datepicker .ui-datepicker-next-hover {
	right: 1px;
}
.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
	display: block;
	position: absolute;
	left: 50%;
	margin-left: -8px;
	top: 50%;
	margin-top: -8px;
}
.ui-datepicker .ui-datepicker-title {
	margin: 0 2.3em;
	line-height: 1.8em;
	text-align: center;
}
.ui-datepicker .ui-datepicker-title select {
	font-size: 1em;
	margin: 1px 0;
}
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
	width: 45%;
}
.ui-datepicker table {
	width: 100%;
	font-size: .9em;
	border-collapse: collapse;
	margin: 0 0 .4em;
}
.ui-datepicker th {
	padding: .7em .3em;
	text-align: center;
	font-weight: bold;
	border: 0;
}
.ui-datepicker td {
	border: 0;
	padding: 1px;
}
.ui-datepicker td span,
.ui-datepicker td a {
	display: block;
	padding: .2em;
	text-align: right;
	text-decoration: none;
}
.ui-datepicker .ui-datepicker-buttonpane {
	background-image: none;
	margin: .7em 0 0 0;
	padding: 0 .2em;
	border-left: 0;
	border-right: 0;
	border-bottom: 0;
}
.ui-datepicker .ui-datepicker-buttonpane button {
	float: right;
	margin: .5em .2em .4em;
	cursor: pointer;
	padding: .2em .6em .3em .6em;
	width: auto;
	overflow: visible;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
	float: left;
}


.ui-datepicker.ui-datepicker-multi {
	width: auto;
}
.ui-datepicker-multi .ui-datepicker-group {
	float: left;
}
.ui-datepicker-multi .ui-datepicker-group table {
	width: 95%;
	margin: 0 auto .4em;
}
.ui-datepicker-multi-2 .ui-datepicker-group {
	width: 50%;
}
.ui-datepicker-multi-3 .ui-datepicker-group {
	width: 33.3%;
}
.ui-datepicker-multi-4 .ui-datepicker-group {
	width: 25%;
}
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
	border-left-width: 0;
}
.ui-datepicker-multi .ui-datepicker-buttonpane {
	clear: left;
}
.ui-datepicker-row-break {
	clear: both;
	width: 100%;
	font-size: 0;
}


.ui-datepicker-rtl {
	direction: rtl;
}
.ui-datepicker-rtl .ui-datepicker-prev {
	right: 2px;
	left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next {
	left: 2px;
	right: auto;
}
.ui-datepicker-rtl .ui-datepicker-prev:hover {
	right: 1px;
	left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next:hover {
	left: 1px;
	right: auto;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane {
	clear: right;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button {
	float: left;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current,
.ui-datepicker-rtl .ui-datepicker-group {
	float: right;
}
.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
	border-right-width: 0;
	border-left-width: 1px;
}
*/