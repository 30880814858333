.printing-document{

	#main-nav,
	SECTION.hero,
	#pdc-footer,
	.no-print {
		display: none !important;
	}
	
	.first-contents {
		margin: 0;
	}
	
}  